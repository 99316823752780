export async function getString(identifier, component = 'moodle', a = null) {
    try {
        return new Promise((resolve) => {
            window === null || window === void 0 ? void 0 : window.require(['core/str'], function ({ get_string }) {
                resolve(get_string(identifier, component, a));
            });
        });
    }
    catch (e) {
        throw new Error('Error loading language string module.');
    }
}
